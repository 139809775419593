@import 'base';
@import 'helpers/splide';
@import 'sections/featured-in-logos';
@import 'sections/trading-contest-banner';
@import 'sections/trading-competition-section';
@import 'sections/blog-categories';
@import 'sections/economic-calendar';
@import 'sections/signals-table';
@import 'sections/smarter-trader';
@import 'blocks/modal-newsletter';
@import '~nice-select2/dist/css/nice-select2.css';

@include economic-calendar-theme-variables('light');

// hero
.section-tag {
  padding: 8px 14px;
  color: #2c7bfd;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  border-radius: var(--border-radius);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.73) 0%,
      rgba(255, 255, 255, 0.73) 100%
    ),
    #a3d3ff;
  width: fit-content;
}

.check-list {
  .check-simple-icon {
    margin-right: 5px;
  }
}

.home-hero-section {
  .home-hero-splide-wrapper.splide {
    $colors-list: #d1e2ff, #e0f6ff, #e9e8ff, #ebf2fe, #d3e3ff, #e2f6ff;
    $colors-count: length($colors-list);
    width: 100%;
    max-width: 100%;

    &::before,
    &::after {
      @media (min-width: 1024px) {
        content: '';
      }
      display: block;
      width: 141px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
    }

    &::before {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      left: 0;
    }

    &::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      right: 0;
    }

    .splide__track {
      height: fit-content;
    }

    .splide__arrows {
      position: absolute;
      top: calc(50% - 25px);
      width: 100%;
      z-index: 3;

      @include round-splide-arrow;

      .splide__arrow--prev {
        position: absolute;
        left: -10px;
      }

      .splide__arrow--next {
        position: absolute;
        right: -10px;
      }
    }

    .hero-slider-card {
      text-decoration: none;
      border-radius: var(--border-radius);
      padding: 16px 0 0;
      color: $dark-text-color;
      display: block;
      position: relative;
      max-height: 400px;

      @for $i from 1 through $colors-count {
        $color-index: if($i <= $colors-count, $i, $i - $colors-count);
        &:nth-child(#{$colors-count}n + #{$i}) {
          background: nth($colors-list, $color-index);
        }
      }

      &__title {
        padding: 0 16px;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      &__text-short {
        padding: 0 24px 30px;
      }

      &__text-long {
        padding: 0 24px 30px;
        flex-grow: 1;
      }

      &__img {
        max-width: calc(100% - 40px);
        position: absolute;
        right: 1px;
        bottom: 0;
        max-height: 150px;
        width: auto;
        border-radius: 0 0 var(--border-radius) 0;
      }

      &__front {
        opacity: 1;
        text-align: center;
        border-radius: var(--border-radius);
        padding-bottom: 170px;
      }

      &__back {
        transition: 0.3s;
        z-index: -1;
        border-radius: 7px;
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 2px);
        background: rgba(0, 0, 0, 0.8);
        height: 100%;
        color: #fff;
        padding-top: 16px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .htt-btn {
        max-width: calc(100% - 32px);
        margin-bottom: 16px;
      }

      &:hover {
        .hero-slider-card__back {
          z-index: 1;
          backdrop-filter: blur(2.5px);
        }
      }
    }
  }
}

.main-content-section {
  position: relative;
}

.sidebar {
  position: absolute;
  background: transparent;
  z-index: 10;
  display: none;
  flex-direction: column;
  height: 385px;
  width: auto;
  left: 16px;

  &.fixed {
    position: fixed;
    top: calc(50% - calc(385px / 2)) !important;
  }

  &.absolute {
    position: absolute;
    top: var(--basic-section-gap);
  }

  &.absolute-bottom {
    position: absolute;
    bottom: var(--basic-section-gap);
    top: unset;
  }

  @media (min-width: 1440px) {
    display: flex;
  }

  .sidebar-nav {
    li {
      padding: 12px 0;
    }

    &__link {
      white-space: nowrap;
      text-decoration: none;
      font-weight: 500;
      cursor: pointer;
      padding: 12px 12px 12px 8px;
      font-weight: 500;
      color: rgba(44, 61, 85, 0.5);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -16px;
        display: block;
        height: 2px;
        width: 8px;
        background-color: rgba(44, 61, 85, 0.5);
      }

      &.active {
        color: $color-htt-blue;
        font-weight: 700;
        padding-left: 16px;

        &::before {
          width: 16px;
          height: 4px;
          background-color: $color-htt-blue;
        }
      }
    }
  }
}

.overwhelmed-section {
  .info-block {
    @include info-block('/static/img/homepage/comp-video-placeholder.png');
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }
}

.live-streams-section {
  .stream-card {
    border-radius: var(--border-radius);
    border: 1px solid #b7d4fb;
    background: #fff;
    padding: 10px;

    .live-stream-indicator {
      display: inline-block;
      border-radius: var(--border-radius);
      background: #e13448;
      padding: 4px 8px 4px 24px;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      position: relative;

      @media (min-width: 576px) {
        font-size: 16px;
      }

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: 8px;
        top: calc(50% - 5px);
      }
    }

    &.live {
      .stream-card__img {
        border-radius: var(--border-radius);
        width: 105px;
        height: 105px;
      }

      .htt-btn {
        width: 100%;
        max-width: 100%;

        @media (min-width: 768px) {
          font-size: 12px;
        }

        @media (min-width: 850px) {
          font-size: 14px;
        }
      }
    }

    &__img {
      border-radius: 50%;
    }

    &__notification {
      cursor: pointer;
    }

    .htt-countdown-block {
      flex-direction: row;
      padding: 6px 10px;
      background: #e1ebff;
      gap: 4px;

      &:first-child[data-value='0'] {
        display: none;
      }

      &::before {
        color: $dark-text-color;
        font-size: 12.5px;
        font-weight: 500;
        line-height: 90%;
      }

      &__unit {
        color: $dark-text-color;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .past-live-streams-slider {
    height: fit-content;
    padding-bottom: 1px;

    .splide__arrows {
      position: absolute;
      bottom: 30px;
      left: calc(50% - calc(168px / 2));
    }

    .splide__track {
      margin-bottom: 70px;
    }
  }

  .past-live-stream {
    background: #fff;
    border-radius: var(--border-radius);
    align-self: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    @media (min-width: 768px) {
      flex: 1 1 calc(50% - 24px);
    }

    @media (min-width: 1024px) {
      flex: 1 1 calc(25% - 24px);
    }

    &__img {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      display: block;

      &__wrap {
        display: block;
        position: relative;

        @include play-button;

        &::after {
          top: 50%;
          width: 50px;
          height: 50px;
          content: none;

          @media (min-width: 768px) {
            content: '';
          }
        }
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      padding: 12px 12px 8px;
      flex-grow: 1;
    }

    &__text {
      font-size: 12px;
      padding: 0 12px 12px;
    }

    &__btn {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      border: 1px solid $color-htt-blue;
      background: #fff;
      text-align: center;
      width: 100%;
      padding: 8px;
      color: $color-htt-blue;
      cursor: pointer;
    }
  }

  .live-stream-panel {
    background: linear-gradient(0deg, #e1ebff 0%, #e1ebff 100%),
      linear-gradient(140deg, #205ab9 14.35%, #19438a 96.97%);
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius);
    gap: 8px;
    flex-wrap: wrap;

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    &__img {
      border: 1px solid $color-htt-blue;
      border-radius: 50%;
    }

    .live-stream-indicator {
      display: inline-block;
      border-radius: var(--border-radius);
      background: #e13448;
      padding: 4px 8px 4px 24px;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      position: relative;

      @media (min-width: 576px) {
        font-size: 16px;
      }

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: 8px;
        top: calc(50% - 5px);
      }
    }

    .htt-btn {
      padding: 4px 6px;
      font-size: 14px;
      max-width: 100%;

      @media (min-width: 1024px) {
        max-width: fit-content;
        flex: 1 1 auto;
      }
    }
  }
}

.market-analysis-section {
  .full-width-line__hr {
    margin: 0;
  }

  .categories-section .categories {
    border-top: none;
    border-bottom: none;

    .category-link {
      @media (min-width: 1024px) {
        margin: 0 8px;
      }

      @media (min-width: 1200px) {
        margin: 0 16px;
      }
    }
  }

  .calendar-link {
    margin-top: 18px;
    margin-bottom: 19px;
  }

  .flex-block-60 {
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }

  .flex-block-40 {
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }

  .email-grabber-generic-form {
    @media (min-width: 768px) {
      max-width: 100%;
    }

    .email-grabber-btn {
      @media (min-width: 768px) {
        max-width: 100%;
      }
    }
  }

  .post-card {
    border: 1px solid $light-grey-border-color;
    color: $dark-text-color;
    text-decoration: none;
    display: flex;
    font-weight: 400;
    flex: 1 1 100%;
    border-radius: var(--border-radius);
    align-self: stretch;
    margin-bottom: 16px;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    &:not(.no-hover) {
      &:hover {
        box-shadow: 0 3px 20px #cfcfcf;
        transform: translateY(-3px);
        transition: 0.3s;
      }
    }

    &__img {
      object-fit: cover;
      border-radius: var(--border-radius) 0 0 var(--border-radius);

      @media (min-width: 768px) {
        width: 180px;
      }
    }

    &__date {
      color: $faded-text-color-dark;
      padding: 0 16px 12px;
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      align-items: center;
    }

    .category-tag {
      border-radius: 3px;
      background: #2c3d55;
      margin: 12px 16px 8px;
      padding: 6px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }

    &__title {
      font-family: var(--heading-font);
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      padding: 0 16px 8px;
      flex: 1 1 auto;
    }

    &__desc {
      padding: 0 16px 8px;
    }
  }
}

.everyday-tools-section {
  .trading-tool {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: var(--border-radius);
    border: 1px solid #e1ecff;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(19, 47, 94, 0.18);
    flex: 1 1 calc(25% - 24px);
    text-decoration: none;
    color: $dark-text-color;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;

    &__heading {
      display: flex;
      align-items: center;
      font-weight: 600;
      gap: 5px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      flex-grow: 1;
    }
  }
}

.trading-resources-section {
  .trading-resource {
    display: flex;
    padding: 16px;
    border-radius: var(--border-radius);
    border: 1px solid #e1ecff;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(19, 47, 94, 0.18);
    flex: 1 1 100%;
    text-decoration: none;
    color: $dark-text-color;
    gap: 6px;
    align-self: stretch;

    @media (min-width: 600px) {
      flex: 1 1 calc(50% - 24px);
    }

    &__icon {
      color: $color-htt-blue;
      background: $light-blue;
      width: 28px;
      height: 28px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 600;
      }

      &__text {
        font-weight: 400;
      }
    }
  }
}
// reset password
.reset-pwd-content {
  margin-top: calc(-1 * var(--header-height));
  width: 100%;

  @media (min-width: 768px) {
    max-width: 343px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Economic calendar section
// Force the mobile overrides as its only in a small container
.table-wrap {
  margin-top: 16px;
  border: 1px solid #cfcfcf;
  box-shadow: none;
  border-radius: var(--border-radius);
}

.economic-calendar-table__body {
  max-height: 602px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.table-wrap .date-switcher .date {
  display: none;
}

.table-wrap .date-switcher .date.arrow-left,
.table-wrap .date-switcher .date.arrow-right {
  display: block;
}

.table-wrap .date-switcher .date.active {
  display: block;
  width: 75%;
  text-align: center;
}

.economic-calendar-table,
.economic-calendar-table__body,
.economic-calendar-table__heading,
.economic-calendar-table__cell,
.economic-calendar-table__row {
  display: block;
}

.economic-calendar-table {
  &__head {
    display: none;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--econ-cal-text);
    position: relative;
  }

  &__cell {
    /* Behave  like a "row" */
    border: none;
    position: relative;

    &:nth-child(1) {
      order: 1;
      width: 19%;
      font-weight: 600;
    }

    &:nth-child(2) {
      width: auto;
      order: 6;
    }

    &:nth-child(3) {
      order: 0;
      width: 12%;
      padding-top: 8px;
      text-align: center;
      font-size: 11px;
      min-width: 0;

      .img {
        width: 19px !important;
      }

      span {
        display: none;
      }
    }

    &:nth-child(4) {
      order: 4;
      width: 63%;
    }

    &:nth-child(5) {
      order: 7;
      font-size: 13px;
      width: auto;

      &::before {
        content: 'Act.';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &:nth-child(6) {
      order: 8;
      font-size: 13px;
      width: auto;

      &::before {
        content: 'Est.';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &:nth-child(7) {
      order: 9;
      font-size: 13px;
      width: auto;

      &::before {
        content: 'Prev.';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &:nth-child(8) {
      order: 10;
      font-size: 13px;
      width: auto;

      &::before {
        content: 'Uni.';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &:nth-child(9) {
      display: block;
      width: 12%;
      position: absolute;
      top: 36%;
      left: 1%;

      .impact {
        height: 6px;
        border-radius: 3px;

        span {
          display: none;
        }

        &::after {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          width: 34%;
        }
      }
    }

    &:nth-child(10) {
      order: 5;
      font-size: 0;
      width: 14%;
      padding: 3px;
      height: auto;

      button {
        font-size: 0;
        background-color: transparent;
        border: none;
        padding: 0;
        margin-left: 8px;

        &::before {
          display: block;
          content: '\002B';
          color: #0987e8;
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
}

.d-flex {
  > div {
    width: 100%;
  }

  #vid-container {
    padding-bottom: 56%;
  }
}
