.trading-contest-banner-section {
  display: none;

  @media (min-width: 1200px) {
    display: block;
    padding-top: var(--small-section-gap);
    padding-bottom: var(--small-section-gap);
  }

  .trading-contest-banner {
    border-radius: var(--border-radius);
    background: linear-gradient(
      90deg,
      #2c7bfd 0.04%,
      #0d2650 38.05%,
      #1d52a9 105.79%
    );
    display: flex;
    gap: 12px;
    justify-content: space-between;
    padding-right: 20px;

    &__img {
      height: 100%;
      width: auto;
      align-self: stretch;
      object-fit: cover;
      max-width: 282px;
    }

    &__content {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 1 210px;

      &__price {
        color: $sm-green;
        font-size: 32px;
      }

      .htt-btn {
        @media (min-width: 576px) {
          max-width: 300px;
          text-transform: none;
          padding: 12px;
        }
      }

      .free-to-enter {
        align-self: center;
      }

      .htt-countdown {
        width: 100%;
        margin: 12px 0;

        .htt-countdown-block {
          background-color: #415570;
          color: #fff;

          &::before {
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .logo {
        display: flex;
        align-items: center;
      }
    }
  }
}
