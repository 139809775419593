.featured-in-section {
  background: #f5f8ff;

  .featured-in-title {
    color: #7b7b7b;
    font-size: 16px;
    font-weight: 400;
  }

  @include logo-slider('--logo-slider-track-width', 'logos', 42px);

  .logo-slider {
    opacity: 0.5;

    &__slide {
      transform: scale(0.8);

      @media (min-width: 1024px) {
        transform: scale(1);
      }
    }
  }
}
