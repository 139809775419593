// Start Trading Competition section
.trading-competition-section {
  &__sponsor-wrapper {
    margin-bottom: 16px;

    small {
      color: #818b9a;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .htt-btn {
    padding: 18px;

    &.btn-mask-icon::after {
      width: 16px;
      height: 16px;
    }
  }

  .img-left {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
  }

  .competition-container {
    border-radius: 5px;
    border: 1px solid rgba(44, 123, 253, 0.33);
    background: rgba(44, 123, 253, 0.1);
    display: flex;
  }

  .competition-card {
    flex: 1 1 calc(33% - 16px);
    padding: 18px 8px;
    position: relative;

    @media (min-width: 768px) {
      padding: 18px;
    }

    @media (min-width: 1024px) {
      border-radius: 0;
    }

    @media (min-width: 1140px) {
      padding: 18px 10px;
    }

    @media (min-width: 1024px) {
      &:not(:last-child)::after {
        content: ' ';
        height: 50%;
        width: 1px;
        background: #e2e2e2;
        position: absolute;
        right: 5%;
        top: 25%;
        bottom: 0;
      }
    }
  }

  .countdown {
    .htt-countdown-block {
      border-radius: var(--border-radius);
      color: $dark-text-color;

      &::before {
        font-size: 21px;
        font-weight: 700;
      }

      &__unit {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .prizes-card {
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    bottom: -30px;
    left: 0px;
    text-align: center;

    @media (min-width: 768px) {
      left: -25px;
    }

    &__icon {
      color: $color-htt-blue;
      position: absolute;
      top: -12px;
      left: -8px;
      width: 20px;
      height: 20px;

      @media (min-width: 1024px) {
        width: 40px;
        height: 40px;
      }
    }

    &__prizes {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2px;

      @media (min-width: 1024px) {
        flex-wrap: nowrap;
        justify-content: flex-start;
      }

      & > div {
        padding: 4px 8px;
        border-radius: 100px;
        display: inline-block;
        text-align: center;
        font-weight: 500;
        margin-right: 3px;

        @media (min-width: 768px) {
          min-width: 46px;
        }
      }

      &__gold {
        background-color: #ffcb5e;
        color: #8d5b16;
      }

      &__silver {
        background-color: #d3dbe9;
        color: #636973;
      }

      &__copper {
        background-color: #e8ac8b;
        color: #77472c;
      }
    }
  }
}
