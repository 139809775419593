@mixin economic-calendar-theme-variables($theme) {
  @if $theme == 'dark' {
    :root {
      --econ-cal-even-row: #39393d;
      --econ-cal-odd-row: #1a1a1c;
      --econ-cal-text: #fff;
      --econ-cal-text-invert: #fff;
      --econ-cal-highlight: #2c7bfd;
    }
  } @else {
    :root {
      --econ-cal-even-row: #f4f6f8;
      --econ-cal-odd-row: #fff;
      --econ-cal-text: #2c3d55;
      --econ-cal-text-invert: #fff;
      --econ-cal-highlight: #609dff;
    }
  }
}

.table-wrap {
  z-index: 4;
  position: relative;
  background: var(--econ-cal-odd-row);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15),
    0 0 0 1px rgba(63, 63, 68, 0.05);
  padding: 0;
  margin-top: 48px;

  .date-switcher {
    width: 100%;
    border-bottom: 1px solid #c4cdd5;
    justify-content: space-between;
    align-content: center;
    padding: 6px 16px;

    .date {
      color: #bfbfbf;
      font-size: 14px;
      font-weight: 600;

      &.active,
      &:hover {
        color: var(--econ-cal-highlight) !important;
      }
    }

    .arrow-left,
    .arrow-right {
      color: var(--econ-cal-text);
      height: 15px;
      margin-top: 2px;
    }

    .arrow-left {
      transform: rotate(90deg);
    }

    .arrow-right {
      transform: rotate(-90deg);
    }
  }
}

.economic-calendar-table {
  max-height: 700px;
  overflow: auto;

  &__head {
    display: block;
  }

  &__body {
    display: block;
    max-height: 600px;
    overflow: auto;

    .economic-calendar-table__row {
      &:hover {
        td {
          background-color: var(--econ-cal-highlight) !important;
          color: var(--econ-cal-text-invert);
        }
        background-color: var(--econ-cal-highlight) !important;
        color: var(--econ-cal-text-invert);
      }

      .lower {
        color: #f8365f;
      }

      .higher {
        color: #2ed42e;
      }

      .same {
        color: #a5a5a5;
      }
    }
  }
  width: 100%;
  border-collapse: collapse;

  &__row:nth-of-type(even) {
    background: var(--econ-cal-even-row);

    @media (min-width: 900px) {
      td {
        &:nth-child(5) {
          background: var(--econ-cal-even-row);
        }
      }
    }
  }

  &__row:nth-of-type(odd) {
    background: var(--econ-cal-odd-row);

    @media (min-width: 900px) {
      td {
        &:nth-child(5) {
          background: var(--econ-cal-odd-row);
        }
      }
    }
  }

  &__heading {
    background: var(--econ-cal-odd-row);
    color: var(--econ-cal-text);
    font-weight: 600;
    text-align: left;
    font-size: 13px !important;
    border-bottom: 1px solid var(--econ-cal-text);

    &:nth-child(1) {
      position: relative;

      &::after {
        content: '\003F';
        display: inline-block;
        position: absolute;
        right: -2px;
        top: 8px;
        font-size: 8px;
        line-height: 11px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: $color-htt-blue;
        color: #fff;
      }

      .dropdown {
        display: none;
        z-index: 14;
      }

      &:hover {
        &::after {
          color: black;
          background-color: var(--econ-cal-odd-row);
          border-color: var(--econ-cal-text);
        }

        .dropdown {
          display: block;
          position: absolute;
          left: 0;
          padding: 6px;
          margin: 0;
          background: #252525;
          border: 1px solid var(--econ-cal-text);
          width: 200%;
          color: var(--econ-cal-text);
          text-align: left;
          font-weight: normal;
          border-radius: var(--border-radius);
        }
      }
    }

    &.sort {
      position: relative;

      &::after {
        content: '\2023';
        display: inline-block;
        transform: rotate(90deg);
        font-size: 22px;
        line-height: 22px;
        position: absolute;
        right: -3px;
        top: calc(50% - 12px);
      }

      .dropdown {
        border: 1px solid lightgrey;
        display: none;
        z-index: 14;
      }

      &:hover {
        color: $color-htt-blue;

        .dropdown {
          display: block;
          list-style: none;
          position: absolute;
          right: 0;
          padding: 6px;
          margin: 0;
          background: var(--econ-cal-even-row);
          width: 175%;
          color: var(--econ-cal-text);
          font-weight: normal;
          cursor: pointer;
          z-index: 101;
          max-height: 300px;
          overflow-y: auto;

          li {
            margin-bottom: 8px;
            text-align: left;

            &:hover {
              color: $color-htt-blue;
            }
          }
        }
      }
    }
  }

  &__cell,
  &__heading {
    padding: 6px;
    border-left: none;
    border-right: none;
    font-size: 14px;
    color: var(--econ-cal-text);

    &:nth-child(1) {
      text-align: center;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(7) {
      width: 9%;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      width: 3%;
    }

    &:nth-child(4) {
      width: 35%;
    }

    &:nth-child(6) {
      width: 5%;
      text-align: center;
    }

    &:nth-child(7) {
      text-align: center;
    }

    &:nth-child(8) {
      width: 10%;
    }

    &:nth-child(9) {
      width: 6%;
    }

    &:last-child {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: center;
      min-width: 68px;

      span {
        display: block;
        width: 100%;
        height: 30px;
        margin-top: -30px;
      }

      .img {
        width: 21px !important;
        //height: 10px !important;
      }
    }
  }

  &__cell {
    &:nth-child(5) {
      color: var(--econ-cal-text);
      font-weight: 600;
      text-align: center;
    }
  }

  &__heading {
    &:nth-child(5) {
      font-weight: 600;
      text-align: center;
    }
  }

  .impact {
    width: 90%;
    height: 13px;
    position: relative;

    &.none {
      background-color: #1eb500;

      &::after {
        width: 99%;
      }
    }

    &.low {
      background-color: #00940e;

      &::after {
        width: 90%;
      }
    }

    &.medium-low {
      background-color: #b4b20d;

      &::after {
        width: 75%;
      }
    }

    &.medium {
      background-color: #ffc600;

      &::after {
        width: 50%;
      }
    }

    &.high {
      background-color: #ff8400;

      &::after {
        width: 25%;
      }
    }

    &.very-high {
      background-color: #d81e00;

      &::after {
        width: 5%;
      }
    }

    &::after {
      content: '';
      display: block;
      background-color: var(--econ-cal-even-row);
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    span {
      display: block;
      width: 100%;
      height: 30px;
      z-index: 2;
      margin-left: -20%;
    }
  }

  &__heading:last-child {
    height: 37px;
  }

  .icon-bell {
    padding: 4px;

    &:hover {
      color: #ffc600;
    }
  }
}

/* Mobile table display */
@media (max-width: 1023px) {
  .table-wrap .date-switcher .date {
    display: none;
  }

  .table-wrap .date-switcher .date.arrow-left,
  .table-wrap .date-switcher .date.arrow-right {
    display: block;
  }

  .table-wrap .date-switcher .date.active {
    display: block;
    width: 75%;
    text-align: center;
  }

  .economic-calendar-table,
  .economic-calendar-table__body,
  .economic-calendar-table__heading,
  .economic-calendar-table__cell,
  .economic-calendar-table__row {
    display: block;
  }

  .economic-calendar-table {
    &__head {
      display: none;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--econ-cal-text);
      position: relative;
    }

    &__cell {
      /* Behave  like a "row" */
      border: none;
      position: relative;

      &:nth-child(1) {
        order: 1;
        width: 19%;
        font-weight: 600;
      }

      &:nth-child(2) {
        width: auto;
        order: 6;
      }

      &:nth-child(3) {
        order: 0;
        width: 12%;
        padding-top: 8px;
        text-align: center;
        font-size: 11px;
        min-width: 0;

        .img {
          width: 19px !important;
        }

        span {
          display: none;
        }
      }

      &:nth-child(4) {
        order: 4;
        width: 63%;
      }

      &:nth-child(5) {
        order: 7;
        font-size: 13px;
        width: auto;

        &::before {
          content: 'Act.';
          display: inline-block;
          margin-right: 5px;
        }
      }

      &:nth-child(6) {
        order: 8;
        font-size: 13px;
        width: auto;

        &::before {
          content: 'Est.';
          display: inline-block;
          margin-right: 5px;
        }
      }

      &:nth-child(7) {
        order: 9;
        font-size: 13px;
        width: auto;

        &::before {
          content: 'Prev.';
          display: inline-block;
          margin-right: 5px;
        }
      }

      &:nth-child(8) {
        order: 10;
        font-size: 13px;
        width: auto;

        &::before {
          content: 'Uni.';
          display: inline-block;
          margin-right: 5px;
        }
      }

      &:nth-child(9) {
        display: block;
        width: 12%;
        position: absolute;
        top: 36%;
        left: 1%;

        .impact {
          height: 6px;
          border-radius: 3px;

          span {
            display: none;
          }

          &::after {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            width: 34%;
          }
        }
      }

      &:nth-child(10) {
        order: 5;
        font-size: 0;
        width: 14%;
        padding: 3px;
        height: auto;

        button {
          font-size: 0;
          background-color: transparent;
          border: none;
          padding: 0;
          margin-left: 8px;

          &::before {
            display: block;
            content: '\002B';
            color: #0987e8;
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .d-flex {
    > div {
      width: 100%;
    }

    #vid-container {
      padding-bottom: 56%;
    }
  }
}

@media (max-width: 465px) {
  .economic-calendar-table {
    &__cell {
      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(1) {
        padding-left: 0;
        width: 22%;
      }

      &:nth-child(9) {
        //top:43%;
      }
    }
  }
}

@media (max-width: 400px) {
  .economic-calendar-table {
    &__cell {
      &:nth-child(1) {
        width: 24%;
      }
    }

    &__cell {
      &:nth-child(3) {
        width: 16%;
      }
    }

    &__cell {
      &:nth-child(4) {
        width: 56%;
      }
    }
  }
}

@media (max-width: 375px) {
  .economic-calendar-table {
    &__cell {
      &:nth-child(9) {
        top: 44%;
        left: 2%;
      }
    }
  }
}

@media (max-width: 340px) {
  .economic-calendar-table {
    &__cell {
      &:nth-child(1) {
        width: 28%;
      }
    }

    &__cell {
      &:nth-child(9) {
        top: 42%;
        left: 2%;
      }
    }
  }
}

.closing {
  background: #e7fce7 !important;
}

.past {
  color: var(--econ-cal-text);
  background: var(--econ-cal-odd-row);

  &:nth-child(even) {
    background: var(--econ-cal-even-row);

    @media (min-width: 900px) {
      td {
        &:nth-child(5) {
          background: var(--econ-cal-even-row);
        }
      }
    }
  }

  &:nth-child(odd) {
    background: var(--econ-cal-odd-row);

    @media (min-width: 900px) {
      td {
        &:nth-child(5) {
          background: var(--econ-cal-odd-row);
        }
      }
    }
  }
}

.last-announced {
  border-bottom: 2px solid darkgrey;
}

.next {
  background-color: #c5f1c5 !important;

  td {
    background-color: #c5f1c5 !important;
  }
}

@-webkit-keyframes updated {
  from {
    background-color: #339966;
  }

  to {
    background-color: inherit;
  }
}

@-moz-keyframes updated {
  from {
    background-color: #339966;
  }

  to {
    background-color: inherit;
  }
}

@-o-keyframes updated {
  from {
    background-color: #339966;
  }

  to {
    background-color: inherit;
  }
}

@keyframes updated {
  from {
    background-color: #339966;
  }

  to {
    background-color: inherit;
  }
}

.updated {
  td {
    -webkit-animation: updated 1s infinite; /* Safari 4+ */
    -moz-animation: updated 1s infinite; /* Fx 5+ */
    -o-animation: updated 1s infinite; /* Opera 12+ */
    animation: updated 1s infinite; /* IE 10+ */
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-button {
  background: #ccc;
}

::-webkit-scrollbar-track-piece {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

@media screen and (min-width: 900px) {
  .economic-calendar-table__head.sticky {
    position: fixed;
    box-shadow: 0 2px 4px #c7c7c7;
    top: 145px;
    width: 1240px;
    z-index: 98;
    padding: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}

[class^='tooltip']::after {
  width: 100%;
  text-align: center;
  font-size: 11px;
  padding: 6px;
}
