.social-proof {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 5;
  max-width: 425px;
  height: 95px;

  @media (max-width: 767px) {
    display: none;
  }

  .social-proof-wrap {
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 425px;
    transform: translateY(500px);
    background: #ffffff;
    border: 1px solid #dadada;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .social-proof-item {
    padding: 16px;
    background: #dfebff;
    display: flex;
    align-items: center;

    strong {
      font-weight: 600;
    }

    .social-proof-icon {
      color: $color-htt-blue;
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
    }

    .user-quote-text {
      font-size: 14px;
      margin-left: 20px;
    }

    .social-proof-plan {
      font-weight: 600;
    }
  }
}
