.categories-section {
  padding-top: 0;
  padding-bottom: 0;
  height: 100% !important;

  .breadcrumbs {
    padding: 20px 0;

    a {
      text-decoration: none;
      color: $dark-text-color;
    }
  }

  .categories {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(44, 123, 253, 0.2);
    border-bottom: 1px solid rgba(44, 123, 253, 0.2);
    align-items: center;

    &__title {
      font-weight: 600;
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    .categories-list {
      position: relative;

      &.mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (min-width: 1024px) {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @media (min-width: 1024px) {
          display: flex;
          align-items: center;
        }
      }

      &__link {
        &:last-child {
          .dropdown {
            .dropdown-list {
              flex: 1 0 calc(50% - 16px);

              &.top-level {
                font-weight: 600;
                flex: 1 1 100%;
                text-align: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .category-link {
      color: $dark-text-color;
      text-decoration: none;
      position: relative;
      margin: 0 8px;
      padding: 20px 0;
      line-height: 18px;

      @media (min-width: 1024px) {
        margin: 0 16px;
      }

      &.active {
        font-weight: 600;
        display: block;

        &::after {
          content: '';
          width: calc(100% - 16px);
          position: absolute;
          height: 2px;
          background: $color-htt-blue;
          bottom: -1px;
          left: 0;
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          & + .dropdown {
            display: flex;
            opacity: 1;
            z-index: 999;
          }
        }
      }
    }

    .dropdown {
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      opacity: 0;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 5px 5px;
      border: 1px solid rgba(44, 123, 253, 0.2);
      box-shadow: 4px 4px 29px 0px rgba(5, 72, 184, 0.15);
      background: #fff;
      overscroll-behavior: contain;
      overflow-y: auto;

      @media (min-width: 1024px) {
        position: absolute;
        top: 55px;
        right: 15px;
        width: auto;
        height: auto;
      }

      &.show {
        padding-top: 60px;
        display: block;
        opacity: 1;
        z-index: 999;

        &::after {
          content: '';
          width: calc(100% - 64px);
          height: 60px;
          position: absolute;
          z-index: -1;
          background: #fff;
          top: 0;
          left: 0;
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          display: flex;
          opacity: 1;
          z-index: 999;
        }
      }

      .dropdown-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 calc(33% - 16px);
        border-bottom: 1px solid rgba(44, 123, 253, 0.2);

        &.top-level {
          font-weight: 600;
          flex: 1 1 100%;
          text-align: center;
          justify-content: center;
          border-top: 1px solid rgba(44, 123, 253, 0.2);

          @media (min-width: 1024px) {
            border-top: none;
          }
        }

        &.close {
          position: absolute;
          top: 0;
          right: 0;
          width: 60px;
          height: 60px;
          border: none;
          z-index: 1;
          background: #fff;
          cursor: pointer;
        }
      }

      .dropdown-link {
        padding: 19px;
        color: $dark-text-color;
        text-decoration: none;
        text-align: center;

        @media (min-width: 1024px) {
          padding: 12px;
        }

        &.top-level {
          font-weight: 600;
          flex: 1 1 100%;
          text-align: center;

          &.active {
            background: $color-htt-blue;
            color: #fff;
          }
        }

        &.active {
          background: $color-htt-blue;
          width: 100%;
          color: #fff;
          font-weight: 600;
        }

        @media (min-width: 1024px) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
