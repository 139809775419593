.signals-table-section {
  .signals-table {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;

    @media (min-width: 1024px) {
      min-height: 566px;
    }

    &__page {
      display: none;

      &.active {
        display: block;
      }
    }

    &__row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-gap: 0 10px;
      border-radius: 5px;
      border: 1px solid #d8e5ff;
      background: $lightest-blue;
      padding: 12px;
      margin-bottom: 24px;
      font-weight: 400;

      @media (min-width: 1024px) {
        display: flex;
        gap: 15px;
        padding: 12px 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__column {
        flex: 1 1 16%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6px;

        @media (min-width: 1024px) {
          gap: 12px;
        }

        &.row {
          flex-direction: row;
          align-items: center;
          flex: 2 1 20%;
          order: 0;
          grid-column: 1 / 3;
          justify-self: flex-start;

          .flags {
            position: relative;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            .instrument {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              border: 1px solid #d8e5ff;
            }

            .first-pair {
              position: relative;
              z-index: 1;
              bottom: -6px;
              left: -4px;
            }

            .second-pair {
              position: absolute;
              z-index: 0;
              right: -4px;
              top: -6px;
            }
          }

          .symbol {
            border-radius: 5px;
            background: #d4e5fe;
            padding: 9px 12px;
            flex: 0 1 85px;
            min-width: 85px;
            text-align: center;
          }

          .trend {
            color: #fff;
            padding: 9px;
            border-radius: 8px;
            flex: 0 1 42px;
            min-width: 42px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.down {
              background-color: #d05656;
            }

            &.up {
              background-color: $sm-green;
            }

            &.neutral {
              background-color: #2c3d55;
            }
          }
        }

        &.stats-section {
          grid-column: 1 / 3;
          flex-direction: row;
          flex: 1 1 33%;
        }

        &.stats {
          align-items: center;
          order: 2;
          font-size: 14px;
          justify-content: space-between;

          @media (min-width: 1024px) {
            order: 1;
          }

          .stats__value {
            border-radius: 5px;
            border: 1px solid rgba(44, 123, 253, 0.2);
            padding: 6px;
            text-align: center;
            width: 100%;

            &.tp {
              color: #31af61;
            }

            &.sl {
              color: #d05656;
            }
          }
        }

        &.details {
          order: 3;
          grid-column: 1 / 2;
          align-self: flex-end;

          @media (min-width: 1024px) {
            order: 3;
          }
        }

        &.trade {
          order: 4;
          grid-column: 2 / 3;
          align-self: flex-end;

          @media (min-width: 1024px) {
            order: 4;
          }
        }

        .htt-btn {
          padding: 8px;
        }
      }
    }

    .sm-partnership-banner-section {
      padding: 0;
      margin-bottom: 24px;

      .htt-container {
        padding: 0;
      }
    }
  }
}

.signals-modal {
  display: none;
  position: fixed;
  z-index: 201;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.on {
    display: block;
  }

  &-content {
    background-color: #fff;
    border-radius: 8px;
    margin: 5% 15px;
    padding: 24px 16px;
    position: relative;

    @media (min-width: 1024px) {
      width: 80%;
      max-width: 1000px;
      margin: 5% auto;
    }
  }

  &-close {
    color: $dark-grey-border-color;
    opacity: 0.8;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .signals-modal-img {
    width: 100px;
  }

  .pips-wrapper {
    display: flex;
    justify-content: space-around;
    gap: 8px;
    padding: 16px;
    border: 1px solid $light-grey-border-color;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
}
