/* stylelint-disable selector-max-type */
@import 'resets';
@import '~svg-spritemap-webpack-plugin/sprites';
@import 'root';
@import 'fonts';
@import 'colors';
@import 'utils';
@import 'typography';
@import 'framework';
@import 'sections/header-navigation';
@import 'sections/footer';
@import 'forms';
@import 'blocks/sign';
@import '~glightbox/dist/css/glightbox.css';
@import 'blocks/social-proof';
@import 'exit-popup';
@import 'blocks/modal-newsletter';

//@import 'blocks/debug-panel';

// lazyload

[data-lazy-background] {
  &,
  *,
  &::before,
  &::after {
    background-image: none !important;
  }
}

// rem units base

html {
  font-size: 14px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

//

body {
  @include default-font;

  background: #fff;
  color: $dark-text-color;
  position: static !important; // fix calendly ios popup scroll to top
  overflow-x: hidden;

  /* stylelint-disable-next-line selector-no-qualifying-type */
  &.preload * {
    animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s,
      padding 0s, margin 0s !important;
  }
}

.lock-scroll {
  overflow: hidden;
  position: relative !important;
  height: 100%;
}

a {
  color: $link-color;
  transition: color 0.25s linear;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.dot-list {
  list-style: none;

  li {
    margin-bottom: 16px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '\2022';
      color: $color-htt-blue;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      flex: 0 0 1em;
    }
  }
}

// override glightbox styles

.goverlay,
.background-blur,
.calendly-overlay {
  background: rgba(15, 15, 15, 0.6);
  backdrop-filter: blur(5px);
}

// page backbone

//@media (min-width: 375px) {
#scroll-wrap {
  overflow-x: hidden;
}
//}

.section-bg {
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-size: cover;
  opacity: 1;
  transition: opacity 0.25s ease;
  overflow: hidden;

  &[data-lazy-background] {
    opacity: 0;
  }

  &.dark {
    background: $dark-bg-color;
  }

  .blurred-ellipse {
    margin: 0 auto;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: rgba(13, 94, 147, 0.75);
    filter: blur(100px);
  }

  .blue-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(139.53deg, #2c7bfd 14.35%, #183f80 96.97%);
  }

  .light-blue-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(139.53deg, #2c7bfd 14.35%, #183f80 96.97%);
  }

  .black-translucent-gradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.9) 50.06%
    );

    @media (min-width: 768px) {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.8) 50.06%
      );
    }
  }

  .blue-translucent-gradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        0deg,
        rgba(44, 123, 253, 0.15),
        rgba(44, 123, 253, 0.15)
      ),
      linear-gradient(
        193.56deg,
        rgba(0, 0, 0, 0.7) 40.56%,
        rgba(0, 0, 0, 0) 104.85%
      );
  }

  &.rounded-corners-mobile {
    :first-child {
      border-radius: 32px;

      @media (min-width: 1024px) {
        border-radius: 0;
      }
    }

    @media (max-width: 1023px) {
      .bottom-black {
        @include rounded-top-bg(#000, 'bottom');
      }

      .top-black {
        @include rounded-top-bg(#000, 'top');
      }
    }
  }
}

.screen-section,
.basic-section,
.extended-section {
  position: relative;
}

.screen-section {
  height: calc(100vh - var(--sticky-height));
  min-height: 600px;
}

.basic-section {
  padding: var(--basic-section-gap) 0;

  &.next-section-bg-same {
    padding-bottom: var(--small-section-gap);
  }

  &.prev-section-bg-same {
    padding-top: var(--small-section-gap);
  }
}

.align-image-bottom {
  @media (min-width: 1200px) {
    padding-bottom: 0;
  }
}

.extended-section {
  padding: var(--extended-section-gap) 0;
}

// make offset for sticky header when header exists //.enable-sticky

.htt-header + #scroll-wrap {
  // padding-top: var(--header-height);
  .basic-section,
  .extended-section,
  .screen-section {
    &:first-child {
      padding-top: var(--header-height);
    }
  }

  .basic-section,
  .extended-section {
    &:first-child {
      .hero-content {
        @media (min-width: 1024px) {
          margin-top: 0;
        }
      }
    }
  }

  & > .screen-section:first-child {
    // margin-top: calc(-1 * var(--sticky-height));
    height: 100vh;
    // padding-top: var(--header-height);

    @media (min-width: 1024px) {
      // padding-top: 0;
      min-height: 768px;
      max-height: 130vw;
    }
  }
}

.basic-heading {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  font-family: var(--heading-font);
  text-transform: capitalize;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  &.full-width-line {
    position: relative;
    display: inline-block;
    margin-bottom: 6px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -7px;
      height: 3px;
      background-color: $color-htt-blue;
      z-index: 2;
    }
  }
}

.full-width-line__hr {
  margin: 0 0 16px;
  height: 1px;
  border: none;
  border-bottom: 1px solid #e4e8ea;
  position: relative;
  z-index: 1;
}

.basic-subheading {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 16px;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
}

.mobile-500 {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    width: auto;
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
  }
}

// grid system

.grid {
  display: grid;
  grid-gap: var(--basic-section-gap);

  &.gap-extended {
    grid-gap: var(--extended-section-gap);
  }

  &-golden-2 {
    grid-template-columns: 1fr;

    @media (min-width: 1024px) {
      grid-template-columns: 57% 45%;
    }
  }

  &-2 {
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, minmax(50%, 1fr));
    }
  }

  &-3 {
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-4 {
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &.gap-1 {
    grid-gap: 16px;
  }

  &.gap-1_5 {
    grid-gap: 24px;
  }

  &.gap-2 {
    grid-gap: 32px;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-items: center;
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  @media (min-width: 1024px) {
    .order-lg-1 {
      order: 1;
    }

    .order-lg-2 {
      order: 2;
    }
  }
}

// standard hero sections

.hero-section {
  .centered {
    justify-content: initial;

    @media (min-width: 1024px) {
      justify-content: center;
    }
  }

  .section-bg {
    &.has-video {
      background: $dark-bg-color;
    }
  }

  .hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(40%);
    transition: opacity 0.5s ease;
  }

  .hero-watch-video-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //.hero-watch-video {
  //  @include play-button(true);
  //
  //  color: $text-color;
  //  text-decoration: none;
  //  font-size: 18px;
  //  font-weight: 400;
  //  white-space: nowrap;
  //  text-shadow: 0 0 1px $dark-bg-color;
  //
  //  &:hover {
  //    text-decoration: underline;
  //  }
  //}

  @keyframes scroll-down-icon-bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-20px);
    }

    60% {
      transform: translateY(-10px);
    }
  }

  .scroll-down-icon-wrap {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .scroll-down-icon {
    color: $text-color;
    display: block;
    width: 12px;
    margin: 0 auto;
    animation: cubic-bezier(0.4, 0, 0.2, 1) scroll-down-icon-bounce 3s infinite;
  }
}

.hero-content {
  @media (min-width: 1024px) {
    padding-top: 0;
    margin-top: calc(-1 * var(--header-height));
  }

  &__highlight {
    font-weight: 700;
    color: $color-htt-blue;
    text-transform: uppercase;
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    line-height: 43px;

    @media (min-width: 1024px) {
      font-size: 50px;
      line-height: 62.5px;
    }
  }

  &__text {
    margin-top: 16px;
  }

  &__list {
    margin-top: 0;
  }
}

// courses shared styles

.award-points-info {
  margin-right: auto;
  background: rgba(44, 123, 253, 0.1);
  color: $color-htt-blue;
  padding: 8px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    margin-right: 8px;
  }
}
